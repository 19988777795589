




































































































































































































































































































































































































































































































































































































































































































































































































































































import {
    AlertBanner,
    ConfirmModal,
    DataModelTree,
    DescriptionListCard,
    DescriptionListItem,
    JsonEditor,
    Scrollbar,
    Tabs,
} from '@/app/components';
import { useAxios, useFeatureFlags, useQueryParams } from '@/app/composable';
import { useBlob } from '@/app/composable/blob';
import { useFilters } from '@/app/composable/filters';
import { useRouter } from '@/app/composable/router';
import { Model } from '@/app/interfaces';
import store from '@/app/store';
import { S } from '@/app/utilities';
import { AccessLevel, AccessLevelsOptions } from '@/modules/access-policy/constants/access-levels.constants';
import {
    AccrualPeriodicityInterval,
    AccrualPeriodicityUnits,
    ModelSource,
    ModelSourceOptions,
} from '@/modules/asset/constants';
import { ModelAPI, RunnerAPI } from '@/modules/data-checkin/api';
import { RetrievalQueryAPI } from '@/modules/retrieval/api';
import { RetrievalQuery } from '@/modules/retrieval/interfaces';
import { ContractsAPI } from '@/modules/sharing/api';
import {
    ArchiveIcon,
    ChevronLeftIcon,
    CloudDownloadIcon,
    PencilAltIcon,
    RefreshIcon,
    ReplyIcon,
    TrashIcon,
} from '@vue-hero-icons/outline';
import { DatabaseIcon } from '@vue-hero-icons/solid';
import { Ref, computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { OrbitSpinner, SelfBuildingSquareSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { AccessPolicy } from '../../access-policy/components';
import { AssetsAPI } from '../api';
import ModelFeature from '../components/ModelFeature.vue';
import QualityTab from '../components/QualityTab.vue';
import { useAsset } from '../composable/asset';
import { useAssetExtentMetadata } from '../composable/asset-extent-metadata';
import { useAssetMetadata } from '../composable/asset-metadata';
import { useAssetRetrieval } from '../composable/asset-retrieval';
import { useAssetStatus } from '../composable/asset-status';
import { AssetType, AssetTypeId, StatusCode } from '../constants';
import { Asset } from '../types';

dayjs.extend(minMax);

export default defineComponent({
    name: 'ViewAsset',
    metaInfo() {
        return { title: (this as any).asset ? (this as any).asset.name : 'View Asset' };
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: {
        OrbitSpinner,
        DataModelTree,
        Tabs,
        AccessPolicy,
        Scrollbar,
        DatabaseIcon,
        ModelFeature,
        DescriptionListCard,
        DescriptionListItem,
        QualityTab,
        TrashIcon,
        PencilAltIcon,
        ArchiveIcon,
        ReplyIcon,
        ChevronLeftIcon,
        ConfirmModal,
        JsonEditor,
        CloudDownloadIcon,
        SelfBuildingSquareSpinner,
        RefreshIcon,
        AlertBanner,
    },
    setup(props, { root }) {
        const SAMPLE_FIELDS_LIMIT = 500;
        const asset: Ref<Asset | undefined> = ref<Asset | undefined>();
        const user = computed(() => store.state.auth.user);
        const { assetTypeName } = useAsset();
        const { isEnabled: isFeatureEnabled } = useFeatureFlags();
        const contract = ref<{ id: string; offlineRetention: string[] } | null>(null);
        const acquiredAssetIds = ref<number[]>([]);

        const {
            isRetrievalDisabled,
            retrievalTooltip,
            accessibilityKeys,
            belongsToUserOrOrganisation,
            canRetrieve,
            isModel,
            isAcquiredAndNotOther,
        } = useAssetRetrieval(asset, user, contract, acquiredAssetIds);
        const router = useRouter();
        const { get, set } = useQueryParams(root, router, 'assets:view');

        const { formatDecimals, fromNow, formatDateTime } = useFilters();
        const tabs = ref<{ title: string; key: string }[]>([]);

        const id: Ref<number | undefined> = computed(() => (props.id ? parseInt(`${props.id}`, 10) : undefined));
        const runner: Ref<{ id: string; name: string } | undefined> = ref(undefined);
        const { exec, error } = useAxios(true);
        const { createTreeStructure, getDomain, expectedUsageOptions, reimbursementMethodOptions } = useAssetMetadata();
        const {
            spatialCoverage,
            temporalCoverage,
            spatialCoverageDescription,
            temporalCoverageDescription,
            hiddenSpatialValuesCount,
        } = useAssetExtentMetadata(asset);
        const metadata = { general: true, distribution: true, extent: true, licensing: true, pricing: true };
        const flatModel = ref<any>(null);
        const dataStructure = ref<any>(null);
        const inputAssets = ref<any>([]);
        const processedSample = ref<any>(null);
        const rejectedItems = ref<number>(0);
        const loading = ref<boolean>(false);
        const loadingProcessedSample = ref<boolean>(false);
        const loadingFlatModel = ref<boolean>(false);

        const updatedDate = computed(() =>
            asset.value?.modifiedAt
                ? dayjs.max([dayjs(asset.value.modifiedAt), dayjs(asset.value.updatedAt)]).toString()
                : asset.value?.updatedAt,
        );

        const isDataOwner = computed(() => user.value.id === asset.value?.createdById);
        const status: Ref<StatusCode | undefined> = computed((): StatusCode | undefined => asset.value?.status);
        const { label: assetStatusLabel, colour: assetStatusClass } = useAssetStatus(status);

        const assetType = computed(() => {
            if (asset.value && asset.value.assetTypeId) return assetTypeName(asset.value.assetTypeId);
            return AssetType.Dataset;
        });
        const showDeleteModal = ref<boolean>(false);

        const refreshProvenanceIds = computed(() => store.state.notificationEngine.refresh.provenanceIds);

        const refresh = computed(
            () =>
                refreshProvenanceIds.value.length &&
                asset.value?.metadata?.provenance?.id &&
                refreshProvenanceIds.value.includes(asset.value.metadata.provenance.id.toString()),
        );

        onBeforeUnmount(() => {
            if (refresh.value) store.dispatch.notificationEngine.clearRefreshProvenanceIds();
        });

        const { download } = useBlob();

        const activeTab: Ref<string | undefined> = computed({
            get: () => get('tab', false, tabs.value.length > 0 ? tabs.value[0].key : undefined),
            set: (newTab: string) => set('tab', newTab, tabs.value.length > 0 ? tabs.value[0].key : undefined),
        });

        const tabIndex: Ref<number> = computed({
            get: () => tabs.value.findIndex((t: { key: string; title: string }) => t.key === activeTab.value),
            set: (newIndex: number) => (activeTab.value = tabs.value[newIndex].key),
        });

        const createTabs = () => {
            tabs.value = [{ key: 'overview', title: 'Overview' }];

            if (
                [AssetType.Dataset, AssetType.Result].includes(assetType.value) &&
                asset.value?.status === StatusCode.Available &&
                asset.value?.metadata?.supportMetrics !== false
            )
                tabs.value.push({ title: 'Quality', key: 'quality' });

            tabs.value.push({ title: 'Sharing Details', key: 'sharingDetails' });
            if (asset.value?.metadata?.model && asset.value?.metadata.model.featureOrder)
                tabs.value.push({ title: 'Model Structure', key: 'modelStructure' });
            if (asset.value?.structure?.primaryConcept)
                tabs.value.push({ title: 'Data Structure', key: 'dataStructure' });
        };

        const loadDataStructure = async () => {
            const primaryConcept = asset.value?.structure?.primaryConcept;
            const schema = asset.value?.structure?.schema;
            if (schema.length > 0) dataStructure.value = createTreeStructure(schema, primaryConcept, flatModel.value);
        };

        const fetchAsset = (assetId: number) => {
            loading.value = true;
            exec(AssetsAPI.getAsset(assetId))
                .then(async (res: any) => {
                    asset.value = res.data;
                    if (user.value.id === asset.value?.createdById && asset.value?.metadata?.runnerId) {
                        // retrieve runner only for creator
                        exec(RunnerAPI.retrieve(asset.value.metadata.runnerId))
                            .then((resRunner: any) => {
                                runner.value = resRunner.data;
                            })
                            .finally(() => {
                                createTabs();
                                loading.value = false;
                            });
                    } else {
                        createTabs();
                        loading.value = false;
                    }
                })
                .catch((e) => {
                    if (e.response.status !== 403) throw e; // if the error is not for forbidden access, then send to sentry
                    (root as any).$toastr.e('You do not have access to view the specific asset.', 'Access Forbidden!');
                    backToAssets();
                });
        };

        const modelDisplayName = computed(() => {
            if (asset.value && asset.value?.metadata?.model) {
                if (asset.value.metadata.model.source === ModelSourceOptions.uploaded) return asset.value.name;
                return asset.value.metadata.model.name;
            }
            return '';
        });

        const customError: Ref<{ title: string; message: string } | undefined> = computed(() => {
            if (!error.value) return undefined;
            // in case of no metrics found or there is active contract,  handle error differently
            if (
                (error.value.response.status === 404 &&
                    error.value.request?.responseURL &&
                    error.value.request.responseURL.match(/\/metrics$/)) ||
                (error.value.response.status === 403 && error.value.response.data?.message?.includes('contracts'))
            )
                return undefined;
            return {
                title: error.value.response.status === 403 ? 'Access Forbidden!' : 'An error has occurred',
                message:
                    error.value.response.status === 403
                        ? 'You do not have access to view the specific asset.'
                        : error.value.message,
            };
        });

        const navigationPayload = computed(() => {
            if (R.pathOr(false, ['metadata', 'provenance', 'id'], asset.value)) {
                if (asset.value?.metadata?.provenance.type === 'data-checkin') {
                    const query: any = { pipeline: asset.value.metadata.provenance.id };
                    if (asset.value.createdById !== user.value.id) query.tab = 'shared';
                    return { name: 'data-checkin-jobs', query };
                } else
                    return {
                        name: 'workflow-designer:edit',
                        params: {
                            id: `${asset.value?.metadata?.provenance.id}`,
                            backTo: 'assets:view',
                            backToId: `${asset.value?.id}`,
                            queryParams: props.queryParams,
                        },
                    };
            }
            return null;
        });

        const confirmDelete = () => {
            showDeleteModal.value = true;
        };

        const showActions = computed(
            () => asset.value && asset.value.createdById === user.value.id && asset.value.status !== StatusCode.Deleted,
        );

        const backToAssets = () => {
            root.$router.push({ name: 'assets', query: JSON.parse(props.queryParams) });
        };

        const editAsset = () => {
            if (asset.value?.assetTypeId === AssetTypeId.Model)
                root.$router.push({
                    name: 'assets:model:edit',
                    params: { id: `${id.value}`, backTo: 'assets:view', queryParams: props.queryParams },
                });
            else
                root.$router.push({
                    name: 'assets:edit',
                    params: { id: `${id.value}`, backTo: 'assets:view', queryParams: props.queryParams },
                });
        };

        const deleteAsset = () => {
            if (id.value)
                exec(AssetsAPI.deleteAsset(id.value))
                    .then((res: any) => {
                        asset.value = res.data;
                        (root as any).$toastr.s('Asset deleted successfuly', 'Success');
                    })
                    .catch((e) => {
                        if (e?.response?.status === 403 && e?.response?.data?.message?.includes('contracts')) {
                            (root as any).$toastr.e(
                                'You are not allowed to delete the specific asset as it is part of an active contract.',
                                'Deletion Forbidden!',
                            );
                        } else {
                            (root as any).$toastr.e('Deleting Asset failed', 'Error');
                        }
                    });
        };

        const modalConfirmed = () => {
            showDeleteModal.value = false;
            deleteAsset();
        };

        const archive = () => {
            if (id.value)
                exec(AssetsAPI.archiveAsset(id.value))
                    .then((res: any) => {
                        asset.value = res.data;
                        (root as any).$toastr.s('Model Asset archived successfuly', 'Success');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Archiving Model Asset failed', 'Error');
                    });
        };

        const restore = () => {
            if (id.value)
                exec(AssetsAPI.restoreArchivedAsset(id.value))
                    .then((res: any) => {
                        asset.value = res.data;
                        (root as any).$toastr.s('Model Asset restored successfuly', 'Success');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Restoring Model Asset failed', 'Error');
                    });
        };

        const isDeleted = computed(() => asset.value?.status === StatusCode.Deleted);

        const retrieve = () => {
            root.$router.push({
                name: 'retrieval:create',
                params: {
                    assetId: id.value,
                    accessibility: accessibilityKeys.value,
                    contractId: contract.value?.id,
                    acquired: isAcquiredAndNotOther.value,
                } as any,
            });
        };

        const downloadProcessedSample = () =>
            download([JSON.stringify(processedSample.value, null, '\t')], 'processedSample.json');

        const refreshPage = () => {
            if (id.value) {
                fetchAsset(id.value);
                store.dispatch.notificationEngine.clearRefreshProvenanceIds();
            }
        };

        const deepFlattenToObject = (obj: any, prefix = '', seperator = '_') => {
            return Object.keys(obj).reduce((acc, k) => {
                if (typeof obj[k] === 'object' && obj[k] !== null) {
                    Object.assign(acc, deepFlattenToObject(obj[k], prefix + k + seperator));
                } else {
                    acc[prefix + k] = obj[k];
                }
                return acc;
            }, {});
        };

        const loadSample = ref<boolean>(true);

        /**
         * Computes a cropped sample based on the flattened representation of a nested sample.
         *
         * This function takes a nested sample, flattens it, and computes a cropped version
         * based on the specified field limit. If the flattened sample has fewer or equal
         * fields than the limit, the original sample is returned. Otherwise, the sample
         *
         * is cropped to include fields up to the specified limit.
         * @returns The computed cropped sample.
         */
        const croppedSample = computed(() => {
            if (!processedSample.value || !processedSample.value?.length) return processedSample.value;
            const flattenedSample = deepFlattenToObject(processedSample.value);
            const keys = Object.keys(flattenedSample);
            if (keys.length <= SAMPLE_FIELDS_LIMIT) return processedSample.value;

            // during flattening, each key is prefixed by the index in the proccessedSample array e.g, "24_Building_heatingDatetime"
            // to handle samples with a large number of fields or rows, if we reach the key at the SAMPLE_FIELDS_LIMIT position,
            // we can extract the original index from the key.
            // This allows us to truncate the sample to stay within the specified limit.
            const lastRow = parseInt(keys[SAMPLE_FIELDS_LIMIT].split('_')[0]); // e.g. lastRow is 24 for "24_Building_heatingDatetime"
            if (lastRow === 0) {
                // we can't even display a single record so don't display anything
                loadSample.value = false;
                return [];
            }
            return processedSample.value.slice(0, lastRow);
        });

        const storageText = computed(() => {
            if (asset.value?.metadata?.distribution?.storage) {
                if (asset.value.metadata.distribution.storage === 'cloud')
                    return 'In the Centralized Data Space of the Data Provider';
                else
                    return `In a Federated Data Space of the Data Provider ${
                        runner.value ? `(${runner.value.name})` : ''
                    }`;
            }
            return '';
        });

        const expectedUsageText = computed(() => {
            if (asset.value?.metadata?.license.expectedUsage.length) {
                return asset.value.metadata.license.expectedUsage
                    .map((expectedUsage: string) => expectedUsageOptions[expectedUsage])
                    .join('; ');
            }
            return '';
        });

        if (id.value) {
            exec(ContractsAPI.getByAssetIds(`${id.value}`)).then((res: any) => {
                if (res?.data?.length > 0) {
                    contract.value = {
                        id: res.data[0].id,
                        offlineRetention: res.data[0].metadata?.license?.offlineRetention ?? [],
                    };
                    if (res.data[0].asset.structure.type !== 'other' && !!res.data[0].asset.structure.primaryConcept)
                        exec(RetrievalQueryAPI.getAcquiredAssetQueries()).then((response: any) => {
                            if (response?.data)
                                acquiredAssetIds.value = R.uniq(
                                    response.data.reduce(
                                        (acc: number[], rq: RetrievalQuery) => R.concat(acc, rq.assetIds ?? []),
                                        [],
                                    ),
                                );
                        });
                }
            });
        }
        watch(
            () => asset.value,
            async () => {
                if (asset.value?.structure?.domain && asset.value.structure?.primaryConcept) {
                    const { majorVersion, uid } = asset.value.structure.domain;
                    const domain: Model = await getDomain(uid, majorVersion);

                    if (domain) {
                        loadingFlatModel.value = true;
                        exec(ModelAPI.conceptNames(domain.id))
                            .then((res: any) => {
                                flatModel.value = res.data;
                                loadingFlatModel.value = false;
                            })
                            .catch(() => (loadingFlatModel.value = false));
                    }

                    loadingProcessedSample.value = true;
                    exec(AssetsAPI.getProcessedSample(asset.value.id))
                        .then((res: any) => {
                            processedSample.value = res.data;
                            loadingProcessedSample.value = false;
                        })
                        .catch(() => (loadingProcessedSample.value = false));
                }

                if (asset.value)
                    exec(AssetsAPI.getInputAssets(asset.value.id)).then((res: any) => {
                        inputAssets.value = res.data?.inputAssets;
                        rejectedItems.value = res.data?.accessControl?.rejectedItems || 0;
                    });
            },
        );

        watch(
            () => flatModel.value,
            () => loadDataStructure(),
        );

        watch(
            () => id.value,
            (newId: number | undefined) => {
                if (!R.isNil(newId)) fetchAsset(newId);
            },
            { immediate: true },
        );

        return {
            S,
            error,
            asset,
            loading,
            tabs,
            activeTab,
            dayjs,
            spatialCoverage,
            metadata,
            dataStructure,
            temporalCoverage,
            temporalCoverageDescription,
            spatialCoverageDescription,
            fromNow,
            user,
            assetStatusLabel,
            assetStatusClass,
            inputAssets,
            assetType,
            isDataOwner,
            modelDisplayName,
            ModelSource,
            formatDecimals,
            customError,
            navigationPayload,
            backToAssets,
            editAsset,
            showDeleteModal,
            modalConfirmed,
            showActions,
            isModel,
            StatusCode,
            archive,
            restore,
            processedSample,
            AccessLevel,
            AccessLevelsOptions,
            belongsToUserOrOrganisation,
            retrieve,
            rejectedItems,
            isRetrievalDisabled,
            loadingProcessedSample,
            loadingFlatModel,
            downloadProcessedSample,
            refresh,
            refreshPage,
            confirmDelete,
            R,
            AccrualPeriodicityUnits,
            AccrualPeriodicityInterval,
            isDeleted,
            hiddenSpatialValuesCount,
            croppedSample,
            loadSample,
            tabIndex,
            retrievalTooltip,
            formatDateTime,
            updatedDate,
            storageText,
            expectedUsageText,
            reimbursementMethodOptions,
            isFeatureEnabled,
            canRetrieve,
        };
    },
});
