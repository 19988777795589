























































































































































































































































import {
    ConfirmModal,
    ESearch,
    FacetsFilters,
    Pagination,
    Scrollbar,
    SearchTerms,
    SvgImage,
    TailwindTableTabs,
    TwSelect,
} from '@/app/components';
import { TButton } from '@/app/components/common';
import { useFacetsFilters, useFeatureFlags, useSearch } from '@/app/composable';
import store from '@/app/store';
import { useAlerts } from '@/modules/alert/composable';
import { AlertSourceType } from '@/modules/alert/constants/alert.constants';
import { RetrievalQueryAPI } from '@/modules/retrieval/api';
import { RetrievalQuery } from '@/modules/retrieval/interfaces';
import { SearchAPI } from '@/modules/search/api';
import { ContractsAPI } from '@/modules/sharing/api';
import { RefreshIcon } from '@vue-hero-icons/outline';
import { BellIcon, FilterIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, onBeforeUnmount, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { AssetsAPI } from '../api';
import { AssetOverview } from '../components';
import { useAssetFacets } from '../composable/asset-facets';
import { useAssetFilters } from '../composable/asset-filters';
import { AssetSortingOption, AssetTypeId, AssetTypes, StatusCode } from '../constants';

const ownershipTabs = [
    { key: 'organisation', label: 'Own Organisation' },
    { key: 'acquired', label: 'Acquired' },
];

export default defineComponent({
    name: 'Assets',
    metaInfo: {
        title: 'My Assets',
    },
    components: {
        OrbitSpinner,
        AssetOverview,
        SvgImage,
        ConfirmModal,
        TailwindTableTabs,
        FilterIcon,
        Pagination,
        TwSelect,
        Scrollbar,
        ESearch,
        FacetsFilters,
        SearchTerms,
        RefreshIcon,
        BellIcon,
        TButton,
    },
    setup(props, { root }) {
        const activeTab = ref<string>('organisation');
        const assetsDetails = computed(() => {
            const datasets = {
                own: [],
                acquired: [],
                sum: 0,
            };
            datasets.own = assets.value ? assets.value.filter((a: any) => a.assetType.id === AssetTypeId.Dataset) : [];
            datasets.acquired = assets.value.filter((a: any) => a.assetType.id === AssetTypeId.Dataset) ? [] : [];
            datasets.sum = datasets.own.length + datasets.acquired.length;

            return { datasets };
        });

        const { flag } = useFeatureFlags();
        const isAlertsEnabled = flag('alerts');
        const isAnalyticsEnabled = flag('analytics');
        const searchableFields = [
            {
                label: 'title',
                key: 'name.keyword',
                textColorClasses: 'text-primary-700',
                backgroundClasses: 'bg-primary-700',
                highlightClasses: 'bg-primary-300',
                widthClasses: 'pl-16 ml-2',
            },
            {
                label: 'description',
                key: 'description.keyword',
                textColorClasses: 'text-teal-700',
                backgroundClasses: 'bg-teal-700',
                highlightClasses: 'bg-teal-300',
                widthClasses: 'pl-28 ml-2',
            },
            {
                label: 'tags',
                key: 'metadata.general.tags.keyword',
                textColorClasses: 'text-purple-700',
                backgroundClasses: 'bg-purple-700',
                highlightClasses: 'bg-purple-300',
                widthClasses: 'pl-20 ',
            },
        ];
        const openFilters = ref<boolean>(true);
        const assetTabs = computed(() => {
            const tabs = [
                { key: 'all', label: 'All' },
                { key: 'datasets', label: 'Datasets' },
            ];
            if (isAnalyticsEnabled.value) tabs.push({ key: 'results', label: 'Results' });
            if (isAnalyticsEnabled.value) tabs.push({ key: 'models', label: 'Models' });
            return tabs;
        });
        const pageSize = 40;
        const showDeleteModal = ref(false);
        const toBeDeleted = ref<number | null>(null);
        const facets = ref<Record<string, { value: string; count: number; selected: boolean }[]>>({});
        const assets = ref<any>([]);
        const rejectedItems = ref<number>(0);
        const totalResults = ref<number>(0);
        const user = ref(store.state.auth.user);
        const acquiredAssetIds = ref<number[]>([]);
        const contracts = ref<
            {
                id: string;
                offlineRetention: string[];
                assetId: number | null;
                barteringAssetId: number | null;
            }[]
        >([]);

        const queryParams = computed(() => JSON.stringify(root.$route.query));

        let checkAlert: any = () => undefined;
        if (isAlertsEnabled.value) {
            const { checkAlert: realCheckAlert, fetchAlerts } = useAlerts(root, [
                AlertSourceType.Dataset,
                AlertSourceType.Result,
            ]);
            checkAlert = realCheckAlert;
            fetchAlerts();
        }

        const refreshProvenanceIds = computed(() => store.state.notificationEngine.refresh.provenanceIds);

        const refresh = computed(
            () =>
                refreshProvenanceIds.value.length &&
                assets.value.find(
                    (asset: any) =>
                        asset.status === StatusCode.Uploading &&
                        asset.metadata?.provenance?.id &&
                        refreshProvenanceIds.value.includes(asset.metadata.provenance.id.toString()),
                ),
        );

        const setSearchQuery = () => {
            switch (tab.value) {
                case AssetTypes.Datasets:
                    searchQuery.value.assetTypeId = AssetTypeId.Dataset;
                    searchQuery.value.facets = reducedDatasetFacets;
                    searchQuery.value.filters = reducedDatasetFilters;
                    break;
                case AssetTypes.Results:
                    searchQuery.value.assetTypeId = AssetTypeId.Result;
                    searchQuery.value.facets = reducedResultFacets;
                    searchQuery.value.filters = reducedResultFilters;
                    break;
                case AssetTypes.Models:
                    searchQuery.value.assetTypeId = AssetTypeId.Model;
                    searchQuery.value.facets = reducedModelFacets;
                    searchQuery.value.filters = reducedModelFilters;
                    break;
                default:
                    delete searchQuery.value.assetTypeId;
                    searchQuery.value.facets = reducedAssetFacets;
                    searchQuery.value.filters = reducedAssetFilters;
            }
        };

        const search = () => {
            setCommonSearchQueryValues();

            searchQuery.value.query.settings = query.value.settings;
            searchQuery.value.settings.excludeSchema = true;
            searchQuery.value.settings.userId = user.value.id;
            searchQuery.value.settings.own = activeTab.value === 'organisation';

            if (user.value.organisationId) searchQuery.value.settings.organisationId = user.value.organisationId;

            setQueryParams();

            const api =
                activeTab.value === 'organisation' ? SearchAPI.myAssetsFacetedSearch : SearchAPI.acquiredAssetsSearch;

            exec(api(searchQuery.value)).then((res: any) => {
                assets.value = res.data?.results;
                facets.value = res.data?.facets;
                rejectedItems.value = res.data?.accessControl?.rejectedItems || 0;
                totalResults.value = res.data?.total;

                // keep only selected statuses
                if (get('reset') && facets.value?.status) {
                    const facetStatus = facets.value.status
                        .filter((statusFacet: any) => statusFacet.selected)
                        .map((statusFacet: any) => statusFacet.value);
                    const statuses = R.clone(searchQuery.value.facets.status);
                    statuses.forEach((statusFacet: string) => {
                        if (!facetStatus.includes(statusFacet))
                            searchQuery.value.facets.status.splice(
                                searchQuery.value.facets.status.indexOf(statusFacet),
                                1,
                            );
                    });
                    root.$router.replace({
                        name: 'assets',
                        query: { status: searchQuery.value.facets.status.join(',') },
                    });
                }
            });
        };

        const {
            exec,
            loading,
            get,
            textNumberFilters,
            page,
            sortBy,
            tab,
            query,
            searchQuery,
            setTab,
            updateCurrentPage,
            facetChanged,
            filterChanged,
            sortByChanged,
            clearFilters,
            setCommonSearchQueryValues,
            setQueryParams,
            unsubscribeSearch,
            sortingFields,
        } = useSearch(
            root,
            'assets',
            pageSize,
            AssetSortingOption,
            isAnalyticsEnabled.value ? 'all' : AssetTypes.Datasets,
            setSearchQuery,
            search,
            ['spatialCoverage'],
        );

        onBeforeUnmount(() => {
            unsubscribeSearch.value ? unsubscribeSearch.value() : undefined;
            if (refresh.value) store.dispatch.notificationEngine.clearRefreshProvenanceIds();
        });

        const {
            assetFacets,
            datasetFacets,
            resultFacets,
            modelFacets,
            reducedAssetFacets,
            reducedDatasetFacets,
            reducedResultFacets,
            reducedModelFacets,
        } = useAssetFacets(get);

        const {
            assetFilters,
            datasetFilters,
            resultFilters,
            modelFilters,
            reducedAssetFilters,
            reducedDatasetFilters,
            reducedResultFilters,
            reducedModelFilters,
        } = useAssetFilters(get);

        const { calculateSearchedFacets, calculateSearchedFilters, defaultValue } = useFacetsFilters([
            'spatialCoverage',
        ]);

        const assetTypeSelected = computed(() => {
            const assetType = Object.values(AssetTypes).includes(tab.value) ? tab.value : 'assets';
            if (rejectedItems.value === 1) return assetType.slice(0, -1);
            return assetType;
        });

        const facetsInfo = computed(() => {
            switch (tab.value) {
                case AssetTypes.Datasets:
                    return datasetFacets.value;
                case AssetTypes.Results:
                    return resultFacets.value;
                case AssetTypes.Models:
                    return modelFacets.value;
                default:
                    return assetFacets.value;
            }
        });

        const filtersInfo = computed(() => {
            switch (tab.value) {
                case AssetTypes.Datasets:
                    return datasetFilters.value;
                case AssetTypes.Results:
                    return resultFilters.value;
                case AssetTypes.Models:
                    return modelFilters.value;
                default:
                    return assetFilters.value;
            }
        });

        const currentSearchedFacetsFilters = computed(() =>
            calculateSearchedFacets(searchQuery.value.facets, facetsInfo.value).concat(
                calculateSearchedFilters(searchQuery.value.filters, filtersInfo.value),
            ),
        );

        const edit = (id: number, assetTypeId: number) => {
            if (assetTypeId === 3) {
                root.$router.push({
                    name: 'assets:model:edit',
                    params: { id: `${id}`, queryParams: queryParams.value },
                });
            } else {
                root.$router.push({
                    name: 'assets:edit',
                    params: { id: `${id}`, queryParams: queryParams.value },
                });
            }
        };

        const view = (id: number) => {
            root.$router.push({
                name: 'assets:view',
                params: { id: `${id}`, queryParams: queryParams.value },
            });
        };

        const navigate = (provenance: { id: number; type: string; name: string }) => {
            if (provenance.type === 'data-checkin') {
                const payload = {
                    name: 'data-checkin-jobs:edit',
                    params: {
                        id: `${provenance.id}`,
                    },
                };

                root.$router.push(payload);
            } else {
                root.$router.push({
                    name: 'workflow-designer:edit',
                    params: { id: `${provenance.id}`, backTo: 'assets', queryParams: queryParams.value },
                });
            }
        };

        const destroy = async () => {
            if (toBeDeleted.value) {
                exec(AssetsAPI.deleteAsset(toBeDeleted.value))
                    .then(async (res: any) => {
                        const asset = assets.value.find((item: any) => item.id === toBeDeleted.value);
                        root.$set(asset, 'status', res.data.status);
                        root.$set(asset, 'updatedAt', res.data.updatedAt);
                        toBeDeleted.value = null;

                        (root as any).$toastr.s('Asset deleted successfuly', 'Success');
                        search();
                    })
                    .catch((e) => {
                        if (e.response?.status === 403) {
                            (root as any).$toastr.e(
                                'You are not allowed to delete the specific asset as it is part of an active contract.',
                                'Deletion Forbidden!',
                            );
                        } else {
                            (root as any).$toastr.e('Deleting Asset failed', 'Error');
                        }
                    });
            }
        };

        const confirmDelete = (id: any) => {
            toBeDeleted.value = id;
            showDeleteModal.value = true;
        };

        textNumberFilters.value = {
            relatedPipeline: defaultValue(get, 'relatedPipeline', false),
            assetOrigin: defaultValue(get, 'assetOrigin', false),
            derivativeAssets: defaultValue(get, 'derivativeAssets', false),
        };
        setSearchQuery();
        search();

        const archiveAsset = (id: number) => {
            exec(AssetsAPI.archiveAsset(id))
                .then(() => {
                    const idx = assets.value.findIndex((asset: any) => asset.id === id);
                    assets.value[idx].status = StatusCode.Archived;
                    (root as any).$toastr.s('Model Asset archived successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Archiving Model Asset failed', 'Error');
                });
        };

        const restoreAsset = (id: number) => {
            exec(AssetsAPI.restoreArchivedAsset(id))
                .then(() => {
                    const idx = assets.value.findIndex((asset: any) => asset.id === id);
                    assets.value[idx].status = StatusCode.Available;
                    (root as any).$toastr.s('Model Asset restored successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Restoring Model Asset failed', 'Error');
                });
        };

        const modalConfirmed = () => {
            showDeleteModal.value = false;
            destroy();
        };

        const cancelAction = () => {
            showDeleteModal.value = false;
            toBeDeleted.value = null;
        };

        const refreshPage = () => {
            search();
            store.dispatch.notificationEngine.clearRefreshProvenanceIds();
        };

        const ownershipTabChanged = (ownershipTab: string) => {
            activeTab.value = ownershipTab;
            search();
        };

        const assetContract = (assetId: number) => {
            const contract = contracts.value.find((c) => c.assetId === assetId || c.barteringAssetId === assetId);
            return contract ? R.pick(['id', 'offlineRetention'], contract) : undefined;
        };

        exec(ContractsAPI.getActive()).then((res: any) => {
            contracts.value = res?.data;
        });

        exec(RetrievalQueryAPI.getAcquiredAssetQueries()).then((res) => {
            if (res?.data)
                acquiredAssetIds.value = R.uniq(
                    res.data.reduce((acc: number[], rq: RetrievalQuery) => R.concat(acc, rq.assetIds ?? []), []),
                );
        });

        return {
            loading,
            assets,
            edit,
            view,
            destroy,
            confirmDelete,
            showDeleteModal,
            tab,
            assetTabs,
            setTab,
            archiveAsset,
            restoreAsset,
            modalConfirmed,
            cancelAction,
            pageSize,
            totalResults,
            updateCurrentPage,
            openFilters,
            navigate,
            facets,
            facetChanged,
            searchQuery,
            sortingFields,
            sortByChanged,
            query,
            sortBy,
            page,
            rejectedItems,
            assetTypeSelected,
            filterChanged,
            clearFilters,
            queryParams,
            facetsInfo,
            filtersInfo,
            currentSearchedFacetsFilters,
            textNumberFilters,
            refresh,
            refreshPage,
            checkAlert,
            searchableFields,
            ownershipTabs,
            activeTab,
            assetsDetails,
            isAlertsEnabled,
            isAnalyticsEnabled,
            ownershipTabChanged,
            assetContract,
            acquiredAssetIds,
        };
    },
});
